/* eslint-disable */

 export const GET_CERTIFICATE_LIST = 'GET_CERTIFICATE_LIST';

 /** @namespace Store/ContainerCertificate/Action/getCertificateList */
 export const getCertificateList = (certificateList, status) => ({
     type: GET_CERTIFICATE_LIST,
     certificateList,
     status
 });
 