/* eslint-disable */

import SourceMenuItem from 'HoeksComponent/MenuItem/MenuItem.component';
import { DEBITORS_GROUP_ID_LIST } from 'Component/DebitorSpecific/DebitorSpecific.config'; 

/** @namespace Component/MenuItem/Component */
export class MenuItem extends SourceMenuItem {
    render() {
        const { item, item: { item_class }, customer: { group_id }, itemMods, isLink } = this.props;
        if (item_class == 'debitor-specifik' && !DEBITORS_GROUP_ID_LIST.includes(group_id)) {
            return null;
        }
        if (isLink) {
            return this.renderItemLinkContent();
        }

        return this.renderItemContent(item, itemMods);
    }
}

export default MenuItem;
