/* eslint-disable */

 import { MyAccountAddressTable as SourceMyAccountAddressTable } from 'HoeksComponent/MyAccountAddressTable/MyAccountAddressTable.component';
 
 /** @namespace Component/MyAccountAddressTable/Component */
 export class MyAccountAddressTable extends SourceMyAccountAddressTable {
 
     get dataPairArray() {
         const { address, getFormatedRegion, showAdditionalFields } = this.props;
         const regionData = getFormatedRegion(address);
 
         const additionalFields = [
             {
                 key: 'region',
                 label: __('State/Province'),
                 source: regionData
             },
             {
                 key: 'city',
                 label: __('City'),
                 source: address
             }
             // Will be back with B2B update
             // {
             //     key: 'company',
             //     label: __('Company'),
             //     source: address
             // }
         ];
 
         return [
             {
                 key: 'firstname',
                 label: __('First name'),
                 source: address
             },
             {
                 key: 'lastname',
                 label: __('Last name'),
                 source: address
             },
             {
                 key: 'street',
                 label: __('Street'),
                 source: address
             },
             {
                 key: 'postcode',
                 label: __('Postal code'),
                 source: address
             },
             {
                 key: 'telephone',
                 label: __('Phone number'),
                 source: address
             },
             {
                key: 'company',
                label: __('Company'),
                source: address
             },
             {
                key: 'country',
                label: __('Country'),
                source: regionData
             },
             {
                key: 'customer_email',
                label: __('Email'),
                source: address
             },
             ...(showAdditionalFields ? additionalFields : [])
         ];
     }
  }
 
 export default MyAccountAddressTable;
 