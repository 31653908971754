/* eslint-disable */

 export const GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST';

 /** @namespace Store/MediaDocument/Action/getDocumentList */
 export const getDocumentList = (documentList, status) => ({
     type: GET_DOCUMENT_LIST,
     documentList,
     status
 });
 