/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Suspense } from 'react';

import { SearchField as SourceSearchField } from 'HoeksComponent/SearchField/SearchField.component';
import { SearchOverlay } from 'SourceComponent/SearchField/SearchField.component';

import './SearchField.override.style';

/** @namespace HoeksPwa/Component/SearchField/Component/SearchFieldComponent */
export class SearchFieldComponent extends SourceSearchField {
    renderSearchIcon() {
        return (
            <div
              block="SearchField"
              elem="SearchFieldWrapper"
              role="button"
              tabIndex="0"
              onClick={ this.openSearch }
              aria-label={ __('Search') }
            >
                <div
                  block="SearchField"
                  elem="SearchIcon"
                />
                <span
                  block="SearchField"
                  elem="Text"
                >
                    { __('Search') }
                </span>
            </div>
        );
    }

    renderSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive
        } = this.props;

        const { showSearch } = this.state;

        if (!showSearch) {
            return null;
        }

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <div
                    block="SearchField"
                    elem="CloseIcon"
                    role="button"
                    tabIndex="0"
                    onClick={ this.closeSearch }
                    aria-label={ __('Close') }
                />
                <input
                  id="search-field"
                  ref={ this.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  onFocus={ onSearchBarFocus }
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                <div
                  block="SearchField"
                  elem="SearchIcon"
                  role="button"
                  tabIndex="0"
                  onClick={ this.onIconClick }
                  aria-label={ __('Search') }
                />
                <Suspense fallback={ this.renderOverlayFallback() }>
                    <SearchOverlay
                      isHideOverlay
                      clearSearch={ this.clearSearch }
                      searchCriteria={ searchCriteria }
                    />
                </Suspense>
            </div>
        );
    }
}

export default SearchFieldComponent;
