/* eslint-disable */

import { MyAccountOrderTableRow as SourceMyAccountOrderTableRow } from 'HoeksComponent/MyAccountOrderTableRow/MyAccountOrderTableRow.component';
export * from 'HoeksComponent/MyAccountOrderTableRow/MyAccountOrderTableRow.component';
import { formatPrice } from 'Util/Price';

/** @namespace Component/MyAccountOrderTableRow/Component */
export class MyAccountOrderTableRow extends SourceMyAccountOrderTableRow {

    render() {
        const {
            base_order_info: {
                created_at,
                name,
                status_label,
                increment_id,
                grand_total_excl_tax
            },
            onViewClick,
            currency_code
        } = this.props;

        return (
            <tr onClick={onViewClick} block="MyAccountOrderTableRow">
                <td>{increment_id ? `#${increment_id}` : ''}</td>
                <td>{name}</td>
                <td>{created_at}</td>
                <td>{status_label}</td>
                <td block="hidden-mobile">
                    {grand_total_excl_tax ? formatPrice(grand_total_excl_tax, currency_code) : ''}
                </td>
            </tr>
        );
    }
}

export default MyAccountOrderTableRow;
