/* eslint-disable */

// imports the original component from vendor folder
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';
import { NEWSLETTER_COLUMN } from './Footer.config';
import CmsBlock from 'Component/CmsBlock';
// import new extended styles file
import './Footer.override.style.scss';

export class Footer extends SourceFooter {
  renderCopyrightContent() {
    return <CmsBlock identifier={'footer-copyright'} />;
  }

  renderCmsBlockWrapper() {
    const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

    return (
      <div
        block="Footer"
        elem="CmsBlockWrapper"
        mix={{ block: 'Footer', elem: 'Content' }}
      >
        <CmsBlock identifier={footer_cms} />
      </div>
    );
  }

  render() {
    const { isVisibleOnMobile, device } = this.props;

    if (isVisibleOnMobile && !device.isMobile) {
      return null;
    }

    if (isVisibleOnMobile && device.isMobile) {
      return null;
    }

    return (
      <div block="FooterTop" elem="FooterHeader">
        <footer block="Footer" aria-label="Footer">
          {this.renderContent()}
          {this.renderCopyrightContent()}
        </footer>
      </div>
    );
  }
}

export default Footer;
