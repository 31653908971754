/* eslint-disable */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductReservationLabel from './ProductReservationLabel.component';
import CustomerProductQuery from 'Query/CustomerProduct.query';
import { fetchQuery } from 'Util/Request';


/** @namespace HoeksPwa/Component/ProductReservationLabel/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace HoeksPwa/Component/ProductReservationLabel/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
});

/** @namespace HoeksPwa/Component/ProductReservationLabel/Container/ProductReservationLabelContainer */
export class ProductReservationLabelContainer extends PureComponent {
    static propTypes = {
    };

    state = {
        isLoading: true,
        status: {}
    }

    componentDidMount(){
        const { product: { reservable } } = this.props;
        if (reservable){
            this.getProductStatus();
        }
    }

    componentDidUpdate(prevProps){
        const { product: { id, reservable } } = this.props;
        const { product: { id: prevId } } = prevProps;
        
        if (reservable && id != prevId){
            this.getProductStatus();
        }
    }



    async getProductStatus(){
        this.setState({status: {}, isLoading: true });
        const { product: { id } } = this.props;
        if (!id) return;
        const { h_reservationStatus } = await fetchQuery(CustomerProductQuery.getProductReservationQuery(id));
        this.setState({status: h_reservationStatus, isLoading: false }, this.addToCartStatus);
    }


    containerProps = () => {
        const { isLoading, status, status: { email } } = this.state;
        const { customer: { email: customerEmail } = {}} = this.props;
        
        return {
            isLoading: isLoading,
            status: status,
            owner: customerEmail && customerEmail == email
        };
    };

    render() {
        const { product: { reservable } } = this.props;
        if (!reservable) return null;
        return (
            <ProductReservationLabel
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReservationLabelContainer);
