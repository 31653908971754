/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { AddToCart as SourceAddToCart } from "SourceComponent/AddToCart/AddToCart.component"
import CartIcon from "./images/icon-cart.svg"
import Form from 'Component/Form';
import './AddToCart.override.style';

/**
 * Button for adding product to Cart
 * @class AddToCart
 * @namespace Component/AddToCart/Component
 */
export class AddToCart extends SourceAddToCart {
    render() {
        const {
            mix,
            product: { type_id },
            isLoading,
            buttonClick,
            onAddToCartSuccess,
            addToCartDisabled
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        return (
            <Form
                id={'ADD_TO_CART'}
                onSubmitSuccess={ onAddToCartSuccess }
            >
                <button
                    // onClick={buttonClick}
                    type="submit"
                    block="Button AddToCart"
                    mix={mix}
                    disabled={addToCartDisabled ? true : isLoading}
                >
                    <span>{__('Add to cart')}</span>      
                    <img className="AddToCart-CartIcon" src={CartIcon} alt="" />
                </button>
            </Form>
        );
    }
}

export default AddToCart;
