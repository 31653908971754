/* eslint-disable */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ProductWishlistButton as SourceProductWishlistButton } from "SourceComponent/ProductWishlistButton/ProductWishlistButton.component"

/** @namespace Component/ProductWishlistButton/Component */
export class ProductWishlistButton extends SourceProductWishlistButton {
    

    render() {
        const { product: { id } = {} } = this.props;

        // if (id !== -1) {
        //     return this.renderContent();
        // }

        return null;
    }
}

export default ProductWishlistButton;
