import Loader from '@scandipwa/scandipwa/src/component/Loader';
import ProductCard from '@scandipwa/scandipwa/src/component/ProductCard/ProductCard.component';
import React from 'react';

// eslint-disable-next-line max-len
import { CarefreeHorizontalScroll } from 'Component/Pagebuilder/components/CarefreeHorizontalScroll/CarefreeHorizontalScroll';
import { useProducts } from 'Component/Pagebuilder/hook/useProducts';

import './ProductScroll.scss';

/** @namespace HoeksPwa/Component/Pagebuilder/Components/ProductScroll/ProductScroll */
export const ProductScroll = (props) => {
    const {
        data,
        loading,
        canRender,
        item
    } = useProducts(props);

    if (canRender) {
        const products = data.products.items.map((productItem, indx) => (
<ProductCard
  /* eslint-disable-next-line react/no-array-index-key */
  key={ indx.toString() }
  product={ productItem }
  availableVisualOptions={ ['label'] }
  device={ {} }
  /* eslint-disable-next-line react/jsx-no-bind */
  getAttribute={ () => '' }
  /* eslint-disable-next-line react/jsx-no-bind */
  isBundleProductOutOfStock={ () => false }
  /* eslint-disable-next-line react/jsx-no-bind */
  isConfigurableProductOutOfStock={ () => false }
  isPreview
  isWishlistEnabled={ false }
  productOrVariant={ productItem }
  thumbnail={ productItem.image.url }
  linkTo={ productItem.url }
  /* eslint-disable-next-line react/jsx-no-bind */
  registerSharedElement={ () => '' }
/>
        ));

        return (
            <CarefreeHorizontalScroll item={ item } _class="product-scroll">
                { products }
            </CarefreeHorizontalScroll>
        );
    } if (loading) {
        return <Loader isLoading />;
    }

    return '';
};
