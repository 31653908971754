/* eslint-disable */
const defaultProps = (originalMember) => ({
    ...originalMember,
    numberOfProductsToDisplay: 8
 });

export default {
    'Component/ProductLinks/Component': {
        'static-member': {
            defaultProps
        }
    }
};
