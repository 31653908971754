/* eslint-disable */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import { orderType } from 'Type/Account';

import { CONFIRM_QUOTE_POPUP_ID } from './MyAccountQuoteConfirmPopup.config';

import './MyAccountQuoteConfirmPopup.style';

/** @namespace Component/MyAccountQuoteConfirmPopup/Component */
export class MyAccountQuoteConfirmPopup extends PureComponent {
    static propTypes = {
        order: orderType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        accept: PropTypes.bool.isRequired
    };

    renderAcceptConfirmation(){
        return (
            <div block="MyAccountQuoteConfirmPopup" elem="AcceptContent">
                <p>{__('Please confirm ordering this order')}</p>
            </div>
        );
    }

    renderRejectConfirmation(){
        return (
            <div block="MyAccountQuoteConfirmPopup" elem="RejectContent">
                <p>{__('Please confirm rejecting this order')}</p>
            </div>
        );
    }

    renderActions(){
        const { onAccept, onReject, isOrdered, accept } = this.props;
        const action = accept ? onAccept : onReject;
        return (
            <div block="MyAccountQuoteConfirmPopup" elem="Actions" mods={{isVisible: !isOrdered}}>
                <div block="MyAccountQuoteConfirmPopup" elem="Button">
                    <button
                        block="Button"
                        mix={{ block: 'MyAccountQuoteConfirmPopup', elem: 'Accept' }}
                        onClick={action}
                    >
                        {__('Accept')}
                    </button>
                </div>          
            </div>
        );
    }

    renderConfirmation(){
        const { accept, isOrdered } = this.props;
        return (
            <div>   
            <div block="MyAccountQuoteConfirmPopup" elem="AcceptContent">
                <p>{this.getText()}</p>
            </div>
            { this.renderActions() }
            </div>
        );
    }

    getText(){
        const { accept, isOrdered } = this.props;
        if (isOrdered){
            if (accept){
                return __('The order has been accepted. You can now see it under my orders.');
            }
            else {
                return __('The order has been cancelled.');
            }
        }
        if (accept){
            return __('Please confirm ordering this order');
        }
        return __('Please confirm rejecting this order');
    }

    renderOrdered() {
        return (
            <div block="MyAccountQuoteConfirmPopup" elem="Ordered">
                <p>{__('The order has been accepted. You can now see it under my orders.')}</p>
            </div>
        );
    }

    renderContent(){
        const { isLoading, isOrdered } = this.props;

        // return this.renderOrdered();
        return this.renderConfirmation();
    }

    render() {
        const { isLoading, isOrdered } = this.props;

        return (
            <Popup
              id={ CONFIRM_QUOTE_POPUP_ID }
              mix={ { block: 'MyAccountQuoteConfirmPopup' } }
            >
                <div block="MyAccountQuoteConfirmPopup" elem="Content">
                    <Loader isLoading={ isLoading } />
                    { this.renderContent() }
                </div>
            </Popup>
        );
    }
}

export default MyAccountQuoteConfirmPopup;
