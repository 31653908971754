/* eslint-disable */

import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { GET_DOCUMENT_LIST } from './MediaDocument.action';

export const MEDIADOCUMENTS = 'MEDIADOCUMENTS';

export const documentList = BrowserDatabase.getItem(MEDIADOCUMENTS) || [];

/** @namespace Store/MediaDocument/Reducer/getInitialState */
export const getInitialState = () => ({
    documentList,
    isLoading: !documentList.length
});

/** @namespace Store/MediaDocument/Reducer */
export const MediaDocumentReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        documentList,
        status
    } = action;

    switch (type) {
    case GET_DOCUMENT_LIST:
        const items = documentList || [];
        BrowserDatabase.setItem(items, MEDIADOCUMENTS, ONE_MONTH_IN_SECONDS);
        return {
            ...state,
            isLoading: status,
            documentList: items
        };

    default:
        return state;
    }
};

export default MediaDocumentReducer;
