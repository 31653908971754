/* eslint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable react/jsx-no-bind */

import {Field as SourceField} from "SourceComponent/Field/Field.component"
import './Field.override.style';

/**
 * Input fields component
 * @class Field
 * @namespace Component/Field/Component
 */
export class Field extends SourceField {
    
}

export default Field;
