/* eslint-disable */

import { Field } from 'Util/Query';
import { isSignedIn } from 'Util/Auth';
import { MyAccountQuery as SourceMyAccountQuery } from 'HoeksQuery/MyAccount.query';

export class MyAccountQuery extends SourceMyAccountQuery {
    _getCustomerFields() {
        return [
            ...super._getCustomerFields(),
            this._getConditionsField()
        ];
    }

    _getConditionsField() {
        return new Field('conditions')
            .addFieldList(this._getConditionsFields());
    }

    _getConditionsFields() {
        return [
            'name',
            'options'
        ];
    }

    _getAddressFields() {
        return [
            ...super._getAddressFields(),
            'company',
            'customer_email'
        ];
    }

    getSetConditionsMutation(conditions) {
        const mutation = new Field('h_setConditionValues')
            .addArgument('conditionValues', '[CustomerCondtionInput]', conditions)
            .addFieldList(['result']);

        return mutation;
    }

    getResetPasswordMutation(options) {
        const { token, id, password, password_confirmation } = options;

        return new Field('s_resetPassword')
            .addArgument('token', 'String!', token)
            .addArgument('password', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addArgument('customer_id', 'String!', id)
            .addField('status');
    }
}

export default new MyAccountQuery();
