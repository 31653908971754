/* eslint-disable */

import { ProductListQuery as SourceProductListQuery } from 'HoeksQuery/ProductList.query';
export * from 'HoeksQuery/ProductList.query';
/**
 * Product List Query
 * @class ProductListQuery
 * @namespace Query/ProductList
 */
export class ProductListQuery extends SourceProductListQuery {
  _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
    return [
      ...super._getProductInterfaceFields(isVariant, isForLinkedProducts),
      'hm_datablad',
      'hm_levering',
      'hm_bestillingsvare',
      'reservable',
      'show_guest_price',
      'customer_description'
    ];
  }
}

export default new ProductListQuery();
