/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import TextPlaceholder from 'Component/TextPlaceholder';
import { isSignedIn } from 'Util/Auth';
import ProductReservationLabel from 'Component/ProductReservationLabel';

import './ProductCard.override.style';
/**
 * Product card
 * @class ProductCard
 * @namespace Component/ProductCard/Component
 */
export class ProductCard extends SourceProductCard {
    renderCardLinkWrapper(children, mix = {}) {
        const { product: { url } } = this.props;

        if (!url) {
            return (
                <div
                    block="ProductCard"
                    elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <a
                block="ProductCard"
                elem="Link"
                href={ url }
                onClick={ this.registerSharedElement }
                mix={ mix }
            >
                { children }
            </a>
        );
    }

  renderMainDetails() {
    const {
      product: { name, sku },
      product
    } = this.props;

    return this.renderCardLinkWrapper(
      <>
        <p block="ProductCard" elem="Sku" mods={{ isloaded: !!sku }}>
          {__('Varenummer :')} {sku}
        </p>
        <p block="ProductCard" elem="Name" mods={{ isLoaded: !!name }}>
          <TextPlaceholder content={name} length="medium" />
        </p>
        <ProductReservationLabel product={product} />
      </>
    );
  }

  renderProductPrice(){
    const { product: { show_guest_price }} = this.props;
    if (!show_guest_price && !isSignedIn()){
      return <div block="ProductCard" elem="PriceWrapper"><p class="ProductPrice">{__('More information')}</p></div>;
    }

    return super.renderProductPrice();
  }

  renderCardContent() {
    const { renderContent } = this.props;

    if (renderContent) {
      return renderContent(this.contentObject);
    }

    return this.renderCardLinkWrapper(
      <>
        <div block="ProductCard" elem="FigureReview">
          <figure block="ProductCard" elem="Figure">
            {this.renderPicture()}
          </figure>
          {this.renderReviews()}
        </div>
        <div block="ProductCard" elem="Content">
          {this.renderAdditionalProductDetails()}
          {this.renderMainDetails()}
          {this.renderProductPrice()}
          {this.renderVisualConfigurableOptions()}
        </div>
      </>
    );
  }
}

export default ProductCard;
