/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Breadcrumb as SourceBreadcrumb } from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.override.style';

/** @namespace Component/Breadcrumb/Component */
export class Breadcrumb extends SourceBreadcrumb {}

export default Breadcrumb;
