/* eslint-disable */

 import { Field } from 'Util/Query';

 /**
  * CustomerDocument Query
  * @class CustomerDocumentQuery
  * @namespace Query/CustomerDocument
  */
 export class MyQuotesQuery {
     getAcceptMutation(orderId) {
         return new Field('h_acceptQuoteOrder')
             .addArgument('order', 'String!', orderId);
     }

     getRejectMutation(orderId) {
        return new Field('h_rejectQuoteOrder')
            .addArgument('order', 'String!', orderId);
     }

 }
 
 export default new MyQuotesQuery();
 