/* eslint-disable */

import { MyAccountMyOrders as SourceMyAccountMyOrders } from 'HoeksComponent/MyAccountMyOrders/MyAccountMyOrders.component';

/** @namespace Component/MyAccountMyOrders/Component */
export class MyAccountMyOrders extends SourceMyAccountMyOrders {
    renderOrderHeadingRow() {
        return (
            <tr>
                <th>{ __('Order') }</th>
                <th>{ __('Name') }</th>
                <th>{ __('Date') }</th>
                <th>{ __('Status') }</th>
                <th block="hidden-mobile">{ __('Total (Excl. Tax)') }</th>
            </tr>
        );
    }
}

export default MyAccountMyOrders;
