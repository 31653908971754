/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BurgerMenuComponent from 'Component/BurgerMenu/BurgerMenu.component';

export const BurgerMenuDispatcher = import(
    'Store/BurgerMenu/BurgerMenu.dispatcher'
);

/** @namespace HoeksPwa/Component/BurgerMenu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    fetchBurgerMenu: () => BurgerMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.fetchBurgerMenu(dispatch)
    )
});

/** @namespace HoeksPwa/Component/BurgerMenu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    burgerMenu: state.BurgerMenuReducer.burgerMenu
});

/** @namespace HoeksPwa/Component/BurgerMenu/Container/BurgerMenuContainer */
export class BurgerMenuContainer extends PureComponent {
    __construct(props) {
        super.__construct(props);
        const {
            // eslint-disable-next-line react/prop-types
            fetchBurgerMenu
        } = this.props;

        fetchBurgerMenu();
    }

    render() {
        return (
            <BurgerMenuComponent
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenuContainer);
