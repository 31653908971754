/* eslint-disable */
import { MenuOverlayComponent as HoeksMenuOverlay } from 'HoeksComponent/MenuOverlay/MenuOverlay.component';
import Link from 'Component/Link';
import { getSortedItems } from 'Util/Menu';
import { appendWithStoreCode } from 'Util/Url';
export * from 'HoeksComponent/MenuOverlay/MenuOverlay.component';
import "./MenuOverlay.override.style.scss"
/** @namespace Component/MenuOverlay/Component */
export class MenuOverlay extends HoeksMenuOverlay {
    renderNav() {
        return (
            <div
                block="MenuOverlay"
                elem="SearchLinkWrapper"
            >
                <Link to={ appendWithStoreCode('/search') } block="MenuOverlay" item="SearchLink">{ __('Search') }</Link>
            </div>
        );
    }

    renderSubLevel(category) {
        const { activeMenuItemsStack } = this.props;
        const { item_id, children } = category;
        const childrenArray = getSortedItems(Object.values(children));
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };

        return (
            <div
                block="Menu"
                elem="SubMenu"
                mods={ { isVisible } }
                key={ item_id }
            >
                <div
                    block="Menu"
                    elem="ItemList"
                    mods={ { ...subcategoryMods } }
                >
                    { childrenArray.map(this.renderSubLevelItems) }
                </div>
            </div>
        );
    }
}

export default MenuOverlay;
