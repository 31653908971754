export const FETCH_BURGER_MENU = 'FETCH_BURGER_MENU';
export const FETCH_BURGER_MENU_FAILURE = 'FETCH_BURGER_MENU_FAILURE';
export const FETCH_BURGER_MENU_SUCCESS = 'FETCH_BURGER_MENU_SUCCESS';

/** @namespace HoeksPwa/Store/BurgerMenu/Action/fetchBurgerMenu */
export const fetchBurgerMenu = () => ({
    type: FETCH_BURGER_MENU
});

/** @namespace HoeksPwa/Store/BurgerMenu/Action/fetchBurgerMenuFailure */
export const fetchBurgerMenuFailure = (error) => ({
    type: FETCH_BURGER_MENU_FAILURE,
    error
});

/** @namespace HoeksPwa/Store/BurgerMenu/Action/fetchBurgerMenuSuccess */
export const fetchBurgerMenuSuccess = (burgerMenu) => ({
    type: FETCH_BURGER_MENU_SUCCESS,
    burgerMenu
});
