/* eslint-disable */


 import { makeCancelable } from 'Util/Promise';
 import { Field, prepareQuery } from 'Util/Query';
 import { executeGet, listenForBroadCast } from 'Util/Request/Request';
 import SourceQueryDispatcher from 'HoeksUtil/Request/QueryDispatcher';
 export * from 'HoeksUtil/Request/QueryDispatcher';
 
 export class QueryDispatcher extends SourceQueryDispatcher {
     handleData(dispatch, options) {
         const { name, cacheTTL } = this;
 
         const rawQueries = this.prepareRequest(options, dispatch);
 
         if (!rawQueries) {
             return;
         }
 
         const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;
         var cancelled = false;
         if (this.promise) {
             this.promise.cancel();
             cancelled = true;
         }
 
         this.promise = makeCancelable(
             new Promise((resolve, reject) => {
                 executeGet(prepareQuery(queries), name, cacheTTL)
                     .then(
                         /** @namespace Util/Request/QueryDispatcher/handleData/executeGetThen */
                         (data) => resolve(data),
                         /** @namespace Util/Request/QueryDispatcher/handleData/executeGetError */
                         (error) => reject(error)
                     );
             })
         );
 
         this.promise.promise.then(
             /** @namespace Util/Request/QueryDispatcher/handleData/thisPromisePromiseThen */
             (data) => this.onSuccess(data, dispatch, options),
             /** @namespace Util/Request/QueryDispatcher/handleData/thisPromisePromiseError */
             (error) => this.onError(error, dispatch, options),
         );
         
         if (!cancelled){
             listenForBroadCast(name).then(
                 /** @namespace Util/Request/QueryDispatcher/handleData/listenForBroadCastThen */
                 (data) => this.onUpdate(data, dispatch, options),
                );
         }
     }
 }
 
 export default QueryDispatcher;
 