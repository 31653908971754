/* eslint-disable */
import { DEBITORS_GROUP_ID_LIST, DEBITORS_CATEGORIES_ID_LIST } from 'Component/DebitorSpecific/DebitorSpecific.config';
import { isSignedIn } from 'Util/Auth';
import { updateProductDetails } from 'Store/Product/Product.action';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

const render = (args, callback, instance) => {
    const result = callback(...args);
    const { categories, customer: { group_id } } = instance.props;
    if (!categories) return result;
    if (!group_id || !DEBITORS_GROUP_ID_LIST.includes(group_id)) {
        const categoryTree = filter(categories);
        return <result.type {...result.props} category={categoryTree} />;
    }
    return result;
}

const filter = (category) => {
    const { id, children } = category;
    if (id && DEBITORS_CATEGORIES_ID_LIST.includes(id)) {
        return null;
    }
    const filteredChildren = [];
    if (children) {
        children.forEach(child => {
            const filteredChild = filter(child);
            if (filteredChild) {
                filteredChildren.push(filteredChild);
            }
        })
    }
    return { ...category, children: filteredChildren };
}

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;
    return { ...callback(...args), customer: state.MyAccountReducer.customer };
};

const logoutMapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        clearProduct: () => dispatch(updateProductDetails({}))
    };
};

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        updateCustomerDetails: () => MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
        )
    };
};

const clearProductAfterSignIn = (args, callback, instance) => {
    const result = callback(...args);
    if (result){
        const [options, dispatch] = args;
        dispatch(updateProductDetails({}));
    }
    return result;
}
const clearProductOnLogout = (args, callback, instance) => {
    const { clearProduct } = instance.props;
    clearProduct();
    return callback(...args);
}
const productComponentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const { productSKU, customer: { group_id: id } = {}} = instance.props;
    const { productSKU: prevProductSKU, customer: { group_id: prevId } = {}} = prevProps;
    if (productSKU == prevProductSKU && id != prevId){
        instance.requestProduct();
    }
    return callback(...args);
}

const loadCustomerDetails = (args, callback, instance) => {
    if (isSignedIn()) {
        const { updateCustomerDetails } = instance.props;
        updateCustomerDetails();
    }
    return callback(...args);
}

const categoryCardRender = (args, callback, instance) => {
    const { category: { id } = {} } = instance.props;
    if (!isSignedIn() && id && DEBITORS_CATEGORIES_ID_LIST.includes(id)) {
        return null;
    }
    return callback(...args);
}


export default {
    'Component/Header/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CategoryMenu/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CategoryMenu/Container': {
        'member-function': {
            render
        }
    },
    'Component/Header/Container': {
        'member-function': {
            componentDidMount: loadCustomerDetails
        }
    },
    'Component/CategoryCard/Component': {
        'member-function': {
            render: categoryCardRender
        }
    },
    'Route/ProductPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate: productComponentDidUpdate
        }
    },
    'Component/MyAccountTabList/Container/mapDispatchToProps': {
        function: logoutMapDispatchToProps
    },
    'Component/MyAccountTabList/Container': {
        'member-function': {
            handleLogout: clearProductOnLogout
        }
    },
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn: clearProductAfterSignIn
        }
    }
};
