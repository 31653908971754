/* eslint-disable */
import { Field } from 'Util/Query';

const addBaseFields = (args, callback) => {
    return [
        ...callback(...args),
        getAddOnProductsField()
    ];
}

const getAddOnProductsField = () => {
    return new Field('hoeks_webAddOnProductGroups')
        .addFieldList([
            'webaddonproductgroup_id',
            'code',
            'description',
            'multiselect',
            'items'
        ]);
}

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields: addBaseFields
        }
    }
}