/** @namespace HoeksPwa/Component/Pagebuilder/Components/CarefreeHorizontalScroll/RandomString/randomString */
// eslint-disable-next-line no-magic-numbers
export const randomString = (charCount = 20) => {
    // eslint-disable-next-line fp/no-let
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    // eslint-disable-next-line fp/no-let
    for (let i = 0; i < charCount; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return btoa(text + Date.now());
};
