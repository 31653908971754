/* eslint-disable */

import { Field } from 'Util/Query';

/**
 * CustomerProduct Query
 * @class CustomerProduct
 * @namespace Query/CustomerProduct
 */
export class CustomerProductQuery {
    getUnreserveProductMutaion(productId) {
        return new Field('h_unreserveProduct')
            .addFieldList(this._getProductReservationFields())
            .addArgument('productId', 'Int!', productId);
    }

    getReserveProductMutaion(productId) {
        return new Field('h_reserveProduct')
            .addFieldList(this._getProductReservationFields())
            .addArgument('productId', 'Int!', productId);
    }

    getProductReservationQuery(productId) {
        return new Field('h_reservationStatus')
            .addFieldList(this._getProductReservationFields())
            .addArgument('productId', 'Int!', productId);
    }

    _getProductReservationFields() {
        return [
            'is_reserved',
            'name',
            'email',
            'expiration'
        ];
    }

}

export default new CustomerProductQuery();
