/* eslint-disable */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Loader from 'Component/Loader';

import './ProductReservationLabel.style';

/** @namespace HoeksPwa/Component/ProductReservationLabel/Component/ProductReservationLabelComponent */
export class ProductReservationLabelComponent extends PureComponent {
    static propTypes = {
        reserve: PropTypes.func,
        reserved_by: PropTypes.string
    };


    renderReservationStatus() {
        const { status: { is_reserved, name, email, expiration } } = this.props;
        if (!is_reserved) return null;
        if (email) {
            return (
                <p block="ProductReservationLabel" elem="Status">{__('Reserved by %s %s', name, email)}
                    <br /><span>{__('Reserved until date %s', expiration)}</span></p>
            )
        }
        return (
            <p block="ProductReservationLabel" elem="Status">
                {__('Product is already reserved')}<br /><span>{__('Reserved until date %s', expiration)}</span>
            </p>
        );
    }

    render() {
        const { isLoading, owner, status: { is_reserved } } = this.props;
        return (
            <div block="ProductReservation">
                {this.renderReservationStatus()}
                <Loader isLoading={isLoading} />
            </div>
        );
    }
}

export default ProductReservationLabelComponent;
