/* eslint-disable */
import { CheckoutQuery as SourceCheckoutQuery } from 'HoeksQuery/Checkout.query';
import { Field } from 'Util/Query';
import { isSignedIn } from 'Util/Auth';
export * from 'HoeksQuery/Checkout.query';

/** @namespace Query/Checkout */
export class CheckoutQuery extends SourceCheckoutQuery {

    getSaveCustomerQuote(guestCartId) {
        const mutation = new Field('h_placeQuoteOrder')
            .setAlias('placeOrder')
            .addField(this._getOrderField());

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', guestCartId);
        }

        return mutation;
    }

    getSetBillingAddressOnCart(input) {
        delete input.billing_address?.address?.region;
        delete input.billing_address?.address?.region_id;
        return super.getSetBillingAddressOnCart(input);
    }
}

export default new CheckoutQuery();
