/* eslint-disable */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './BurgerMenu.style';

/** @namespace HoeksPwa/Component/BurgerMenu/Component/BurgerMenuComponent */
export class BurgerMenuComponent extends PureComponent {
    static propTypes = {
        burgerMenu: PropTypes.array.isRequired,
        showBurgerMenu: PropTypes.bool.isRequired
    };

    renderChildrenRecursively(child, level = 1, self) {
        const {
            burgerMenu: { items }
        } = self.props;

        const children = items?.filter((item) => item?.parent_id === parseInt(child?.item_id))
                                .sort((a, b) => a.position - b.position);

        return (
            <li
                block="BurgerMenu"
                elem="SectionContentItem"
                mods={ { level } }
            >
                <a
                    block="BurgerMenu"
                    elem="ItemLink"
                    id={ child?.item_id }
                    href={ child?.url }
                >
                    { __(child.title) }
                </a>
                { children.length > 0 && (
                    <ul
                        block="BurgerMenu"
                        elem="SectionContent"
                    >
                        { children.map((child) => this.renderChildrenRecursively(child, level + 1, self)) }
                    </ul>
                ) }
            </li>
        );
    }

    renderSection(sectionHeader) {
        const {
            burgerMenu: { items }
        } = this.props;

        const children = items?.filter((item) => item?.parent_id === parseInt(sectionHeader?.item_id))
                                          .sort((a, b) => a.position - b.position);
        const self = this;

        return (
            <div
                block="BurgerMenu"
                elem="Section"
            >
                <h3
                    block="BurgerMenu"
                    elem="SectionHeader"
                >
                    { __(sectionHeader?.title) }
                </h3>
                <ul
                    block="BurgerMenu"
                    elem="SectionContent"
                >
                    { children.map((child) => this.renderChildrenRecursively(child, 1, self)) }
                </ul>
            </div>
        );
    }

    renderOverlay() {
        const {
            burgerMenu: { items },
            showBurgerMenu
        } = this.props;

        const sectionHeaders = items?.filter((item) => item?.item_class === 'burger-menu-subheader');
        const isVisible = showBurgerMenu === undefined ? false : showBurgerMenu;

        return (
            <div
                block="BurgerMenu"
                elem="Overlay"
                mods={ { isVisible } }
            >
                { sectionHeaders?.map(this.renderSection.bind(this)) }
            </div>
        );
    }

    render() {
        const {
            burgerMenu
        } = this.props;

        if (!burgerMenu?.is_active === true) {
            return null;
        }

        return (
            <div
                block="BurgerMenu"
                elem="Wrapper"
            >
                { this.renderOverlay() }
            </div>
        );
    }
}

export default BurgerMenuComponent;
