/* eslint-disable */
import { AddToCartContainer as SourceAddToCartContainer, mapStateToProps } from "SourceComponent/AddToCart/AddToCart.container";
import { connect } from 'react-redux';
import { executeGet } from 'Util/Request';
import { prepareQuery } from 'Util/Query';
import ProductListQuery from 'Query/ProductList.query';
import { showNotification } from 'Store/Notification/Notification.action';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    addProducts: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductsToCart(dispatch, options)
    ),
    removeFromWishlist: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export class AddToCartContainer extends SourceAddToCartContainer {
    containerFunctions = {
        buttonClick: this.buttonClick.bind(this),
        onAddToCartSuccess: this.onAddToCartSuccess.bind(this)
    };

    addSimpleProductToCart(products = null) {
        const {
            product,
            quantity,
            addProduct,
            addProducts,
            productOptionsData
        } = this.props;

        if (!products) {
            addProduct({
                product,
                quantity,
                productOptionsData
            }).then(
                /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
                () => this.afterAddToCart(),
                /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
                () => this.resetLoading()
            );
        } else {
            const options = products.map((product) => {return {product, quantity: 1, productOptionsData: {}}}); 
            options.unshift({
                product,
                quantity,
                productOptionsData
            });
            addProducts(options).then(
                /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
                () => this.afterAddToCart(),
                /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
                () => this.resetLoading()
            );
        }
    }

    onAddToCartSuccess(fields) {
        const keys = Object.keys(fields);
        const productSku = keys.filter(key => {
            return fields[key];
        });
        if (productSku.length != 0) {
            const products = productSku.map((sku) =>  { return { sku: sku, type_id: 'simple'}});
            this.buttonClick(products);
        }
        else {
            this.buttonClick();
        }
    }

    buttonClick(products) {
        const {
            product: { type_id } = {},
            onProductValidationError,
            disableHandler
        } = this.props;

        if (disableHandler) {
            return;
        }

        if (!this.validateAddToCart()) {
            onProductValidationError(type_id);
            return;
        }

        this.setState({ isLoading: true }, () => this.addProductToCart(products));
    }

    addProductToCart(products = null) {
        const {
            product: { type_id }
        } = this.props;

        const addToCartHandler = this.addToCartHandlerMap[type_id];

        if (addToCartHandler) {
            addToCartHandler();
            return;
        }

        this.addSimpleProductToCart(products);
    }

    fetchWebAddOnProducts(ProductsSku) {
        return executeGet(prepareQuery(this.prepareWebAddOnProductRequest(ProductsSku)), 'WebAddOns', 86400)
            .then((result) => {
                return result.products.items;
            })
            .catch(
                (e) => console.log('error', 'Error fetching web add ons', e)
            )
    }

    prepareWebAddOnProductRequest(ProductsSku) {
        return [
            ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsSkuArray: ProductsSku
                    }
                },
                notRequireInfo: true
            })
        ];
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);