/* eslint-disable */

import { MyAccountMyOrders } from 'HoeksComponent/MyAccountMyOrders/MyAccountMyOrders.component';

import MyAccountOrderPopup from 'Component/MyAccountOrderPopup';
import MyAccountQuoteTableRow from 'Component/MyAccountQuoteTableRow';

import 'Component/MyAccountMyOrders/MyAccountMyOrders.style';
import MyAccountQuoteConfirmPopup from 'Component/MyAccountQuoteConfirmPopup';

import './MyAccountMyQuotes.style';

/** @namespace Component/MyAccountMyQuotes/Component */
export class MyAccountMyQuotes extends MyAccountMyOrders {
    renderPopup() {
        return (
            <div>
                <MyAccountOrderPopup /> 
                <MyAccountQuoteConfirmPopup />
            </div>
            );
    }

    renderTable() {
        return (
            <table block="MyAccountMyQuotes" elem="Table">
                <thead>
                    { this.renderOrderHeadingRow() }
                </thead>
                <tbody>
                    { this.renderOrderRows() }
                </tbody>
            </table>
        );
    }

    renderOrderHeadingRow() {
        return (
            <tr>
                <th>{ __('Quote') }</th>
                <th>{ __('Name') }</th>
                <th>{ __('Date') }</th>
                <th>{ __('Status') }</th>
                <th block="hidden-mobile">{ __('Total') }</th>
                <th>{ __('Actions') }</th>
            </tr>
        );
    }

    renderNoOrders() {
        const { device } = this.props;
        return (
            <tr block="MyAccountMyOrders" elem="NoOrders">
                { /* eslint-disable-next-line no-magic-numbers */ }
                <td colSpan={ device.isMobile ? 3 : 4 }>{ __('You have no quotes.') }</td>
            </tr>
        );
    }

    renderOrderRow = (order) => {
        const { base_order_info: { id } } = order;

        return (
            <MyAccountQuoteTableRow
              key={ id }
              order={ order }
            />
        );
    };
}

export default MyAccountMyQuotes;
