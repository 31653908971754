import React, { useEffect } from 'react';
import { PageBuilderComponent } from 'simi-pagebuilder-react';

import { Category } from 'Component/Pagebuilder/components/Category/Category';
import { CategoryScroll } from 'Component/Pagebuilder/components/CategoryScroll/CategoryScroll';
import ProductGrid from 'Component/Pagebuilder/components/ProductGrid/ProductGrid';
import ProductList from 'Component/Pagebuilder/components/ProductList/ProductList';
import { ProductScroll } from 'Component/Pagebuilder/components/ProductScroll/ProductScroll';

/** @namespace HoeksPwa/Route/NoMatch/PagebuilderWrapper/PagebuilderNoMatchWrapper/Component/PagebuilderNoMatchWrapperComponent */
export const PagebuilderNoMatchWrapperComponent = (props) => {
    const {
        // eslint-disable-next-line react/prop-types
        updateBreadcrumbs,
        // eslint-disable-next-line react/prop-types
        cleanUpTransition,
        // eslint-disable-next-line react/prop-types
        pageMaskedId,
        // eslint-disable-next-line react/prop-types
        pageData,
        // eslint-disable-next-line react/prop-types
        endPoint
    } = props;

    // eslint-disable-next-line react/prop-types
    const isValidPage = !!pageData && !!pageData.publish_items;
    // eslint-disable-next-line react/prop-types
    const pageName = pageData.name;
    // eslint-disable-next-line react/prop-types
    const urlPath = pageData.url_path;
    const dispatchData = isValidPage ? pageData : false;

    const breadcrumbs = [
        {
            url: isValidPage ? urlPath : '',
            name: isValidPage ? __(pageName) : __('Not Found')
        }
    ];

    // eslint-disable-next-line react/prop-types
    if (isValidPage && urlPath.startsWith('/cases')) {
        breadcrumbs.push({
            url: '/kundecases',
            name: __('Cases')
        });
    }

    useEffect(() => {
        updateBreadcrumbs(breadcrumbs);
        cleanUpTransition();
    }, [isValidPage]);

    return (
        // eslint-disable-next-line react/no-unknown-property
        <div block="SimiPagebuilder">
            <PageBuilderComponent
              key={ pageMaskedId }
              pageData={ dispatchData }
              endPoint={ endPoint }
              maskedId={ pageMaskedId }
              toPreview={ false }
              Category={ Category }
              ProductList={ ProductList }
              ProductGrid={ ProductGrid }
              ProductScroll={ ProductScroll }
              CategoryScroll={ CategoryScroll }
            />
        </div>
    );
};
