/* eslint-disable */

 import { connect } from 'react-redux';
 import MyAccountMyQuotes from './MyAccountMyQuotes.component';
 import { MyAccountMyOrdersContainer as SourceMyAccountMyOrders, mapStateToProps, mapDispatchToProps} from 'Component/MyAccountMyOrders/MyAccountMyOrders.container';
 
 /** @namespace Component/MyAccountMyQuotes/Container */
 export class MyAccountMyQuotesContainer extends SourceMyAccountMyOrders {
      getFilteredOrderList(){
        const { orderList } = this.props;
        return orderList.filter(({base_order_info: {status}}) => status == 'hm_quote');
    }
 
     render() {
         return (
             <MyAccountMyQuotes
               { ...this.props }
               orderList={this.getFilteredOrderList()}
             />
         );
     }
 }
 
 export default connect(mapStateToProps, mapDispatchToProps)(MyAccountMyQuotesContainer);
 