/* eslint-disable */ 
import { CartQuery as SourceCartQuery } from 'HoeksQuery/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Query/Cart */
export class CartQuery extends SourceCartQuery {
    getSaveCartItemsMutation(products, quoteId) {
        const mutation = new Field('h_saveCartItems')
            .setAlias('saveCartItem')
            .addArgument('cartItems', '[CartItemInput]', products)
            .addFieldList(this._getSaveCartItemFields(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }
}

export default new CartQuery();
