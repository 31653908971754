/* eslint-disable */

import Image from 'Component/Image';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import { formatPrice } from 'Util/Price';

import { ORDER_POPUP_ID } from './MyAccountOrderPopup.config';

import { MyAccountOrderPopup as SourceMyAccountOrderPopup } from 'HoeksComponent/MyAccountOrderPopup/MyAccountOrderPopup.component';
import './MyAccountOrderPopup.extend.style';

/** @namespace Component/MyAccountOrderPopup/Component */
export class MyAccountOrderPopup extends SourceMyAccountOrderPopup {

    renderShipping() {
        const { order: { shipping_info }, currency_code } = this.props;

        const {
            shipping_description,
            shipping_address,
            shipping_amount
        } = shipping_info || {};

        if (!shipping_address) {
            return null;
        }

        return (
            <div block="MyAccountOrderPopup" elem="ShippingWrapper">
                <h4>{__('Shipping & Handling Information')}</h4>
                <dl>
                    <dt>{__('Description: ')}</dt>
                    <dd>
                        {shipping_description}
                    </dd>
                    <dt>{__('Price: ')}</dt>
                    <dd>
                        {formatPrice(shipping_amount, currency_code)}
                    </dd>
                </dl>
                {this.renderShippingAddressTable()}
            </div>
        );
    }

    renderItems() {
        const { order: { order_products = [] }, currency_code } = this.props;

        return order_products.map((product, i) => {
            const {
                name,
                thumbnail,
                id,
                qty,
                row_total_excl_tax
            } = product;

            const { url } = thumbnail || {};

            return (
                <tr
                    key={id || i.toString()}
                    block="MyAccountOrderPopup"
                    elem="Row"
                >
                    <td>
                        {url && (
                            <Image
                                src={url}
                                alt={name}
                                mix={{ block: 'MyAccountOrderPopup', elem: 'Thumbnail' }}
                            />
                        )}
                    </td>
                    <td>{name}</td>
                    <td>{qty}</td>
                    <td>
                        {formatPrice(row_total_excl_tax, currency_code)}
                    </td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { order: { base_order_info, shipping_info }, currency_code } = this.props;
        const { grand_total, grand_total_excl_tax, sub_total_excl_tax } = base_order_info || {};
        const { shipping_amount } = shipping_info || {};
        return (
            <div block="MyAccountOrderPopup" elem="OrderWrapper">
                <h4>{__('Order Total')}</h4>
                <dl>
                    <dt>{__('Subtotal: ')}</dt>
                    <dd>
                        {formatPrice(sub_total_excl_tax, currency_code)}
                    </dd>
                    <dt>{__('Shipping: ')}</dt>
                    <dd>
                        {formatPrice(shipping_amount, currency_code)}
                    </dd>
                    <dt>{__('Tax: ')}</dt>
                    <dd>
                        {formatPrice(grand_total-grand_total_excl_tax, currency_code)}
                    </dd>
                    <dt>{__('Grand total: ')}</dt>
                    <dd>
                        {formatPrice(grand_total, currency_code)}
                    </dd>
                </dl>
            </div>
        );
    }

    renderContent() {
        const { order: { order_products } } = this.props;

        if (!order_products) {
            return null;
        }

        return (
            <>
                {this.renderBaseInfo()}
                {this.renderPayment()}
                {this.renderShipping()}
                {this.renderProducts()}
                {this.renderTotals()}
            </>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <Popup
                id={ORDER_POPUP_ID}
                mix={{ block: 'MyAccountOrderPopup' }}
            >
                <div block="MyAccountOrderPopup" elem="Content">
                    <Loader isLoading={isLoading} />
                    {this.renderContent()}
                </div>
            </Popup>
        );
    }
}

export default MyAccountOrderPopup;
