/* eslint-disable */

import { connect } from 'react-redux';
import { MyAccountOrderTableRowContainer as SourceMyAccountOrderTableRowContainer, mapStateToProps } from 'HoeksComponent/MyAccountOrderTableRow/MyAccountOrderTableRow.container';
import { ORDER_POPUP_ID } from 'Component/MyAccountOrderPopup/MyAccountOrderPopup.config';
import { CONFIRM_QUOTE_POPUP_ID, ACCEPT, REJECT } from 'Component/MyAccountQuoteConfirmPopup/MyAccountQuoteConfirmPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';

import MyAccountQuoteTableRow from './MyAccountQuoteTableRow.component';

/** @namespace Component/MyAccountOrderTableRow/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(ORDER_POPUP_ID, payload)),
    showAcceptPopup: (payload) => dispatch(showPopup(CONFIRM_QUOTE_POPUP_ID, payload)),
});

/** @namespace Component/MyAccountQuoteTableRow/Container */
export class MyAccountQuoteTableRowContainer extends SourceMyAccountOrderTableRowContainer {
    containerFunctions = {
        onViewClick: this.onViewClick.bind(this),
        onAccept: this.onAccept.bind(this),
        onReject: this.onReject.bind(this)
    };

    onAccept(e) {
        e.stopPropagation();
        const { showAcceptPopup, order } = this.props;
        const { base_order_info: { increment_id } } = order;

        showAcceptPopup({
            title: __('Order #%s', increment_id),
            increment_id,
            order,
            accept: true
        });
    }
    
    onReject(e) {
        e.stopPropagation();
        const { showAcceptPopup, order } = this.props;
        const { base_order_info: { increment_id } } = order;

        showAcceptPopup({
            title: __('Order #%s', increment_id),
            increment_id,
            order,
            accept: false
        });
    };

    render() {  
        return (
            <MyAccountQuoteTableRow
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountQuoteTableRowContainer);
