/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/**
 * Product price
 * @class ProductPrice
 * @namespace Component/ProductPrice/Component
 */
export class ProductPrice extends SourceProductPrice {
  renderCurrentPrice() {
    const { discountPercentage, formattedFinalPrice, displayTaxInPrice } =
      this.props;
    let TaxIncludeExcludeText;

    if (displayTaxInPrice === 'DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX') {
      TaxIncludeExcludeText = 'Excl. Tax';
    } else if (
      displayTaxInPrice === 'DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX'
    ) {
      TaxIncludeExcludeText = 'Incl. Tax';
    }
    const priceSchema = this.getCurrentPriceSchema();

    // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
    const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

    // force unequal comparatment - unsure of resulting type
    // eslint-disable-next-line eqeqeq
    if (formattedFinalPrice == 0) {
      return null;
    }

    return (
      <PriceSemanticElementName>
        <span {...priceSchema}>{formattedFinalPrice}</span>

        <span className="TaxTag">{__(`(${TaxIncludeExcludeText})`)}</span>
      </PriceSemanticElementName>
    );
  }

  renderOldPrice() {
    return null;   
  }

}

export default ProductPrice;
