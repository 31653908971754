/* eslint-disable */
import HoeksMenu from 'HoeksComponent/Menu/Menu.component';
import { getSortedItems } from 'Util/Menu';
import MenuItem from 'Component/MenuItem';
import ExpandableContent from 'Component/ExpandableContent/ExpandableContent.component';
import './Menu.override.style';
export * from 'HoeksComponent/Menu/Menu.component';

export class Menu extends HoeksMenu {
  renderAdditionalInformation(checkMobile = false) {
    const { device } = this.props;
    if (checkMobile && !device.isMobile) {
      return null;
    }

    return (
      <>
        {this.renderStoreSwitcher()}
        {this.renderCurrencySwitcher()}
        {this.renderComparePageLink()}
        {/* {this.renderPromotionCms()} */}
      </>
    );
  }

  renderDesktopSubLevelItems(item, mods) {
    const { item_id, item_class } = item;
    const { closeMenu, activeMenuItemsStack } = this.props;

    const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';
    const itemMods = { ...mods, isHideOnDesktop };

    return (
      <MenuItem
        activeMenuItemsStack={activeMenuItemsStack}
        item={item}
        itemMods={itemMods}
        closeMenu={closeMenu}
        isLink
        key={item_id}
      />
    );
  }
}

export default Menu;
