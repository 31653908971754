/* eslint-disable */

import { Field } from 'Util/Query';
import { OrderQuery as SourceOrderQuery } from 'HoeksQuery/Order.query';
 /**
  * Order Query
  * @class OrderQuery
  * @namespace Query/Order
  */
 export class OrderQuery extends SourceOrderQuery {
     _getBaseOrderInfoFields(isList) {
         return [
             ...super._getBaseOrderInfoFields(isList),
             'status',
             'grand_total_excl_tax',
             'sub_total_excl_tax',
             'name'
         ];
     }

     _getDefaultFields(isList) {
        return [
            ...super._getDefaultFields(isList),
            'row_total_excl_tax'
        ];
    }
 }
 
 export default new OrderQuery();
 