/* eslint-disable */

import SourceMenuItemContainer from 'HoeksComponent/MenuItem/MenuItem.container';

 import { connect } from 'react-redux';
  
 /** @namespace Component/Menu/Container/mapStateToProps */
 // eslint-disable-next-line no-unused-vars
 export const mapStateToProps = (state) => ({
     customer: state.MyAccountReducer.customer,
     device: state.ConfigReducer.device
 });
 
 /** @namespace Component/Menu/Container/mapDispatchToProps */
 // eslint-disable-next-line no-unused-vars
 export const mapDispatchToProps = (dispatch) => ({});
 
 
 export default connect(mapStateToProps, mapDispatchToProps)(SourceMenuItemContainer);
 