/* eslint-disable */
import { PureComponent } from 'react';
import { Redirect } from 'react-router-dom'

/** @namespace Component/PdfTabs/Component */
export class Out extends PureComponent {

    renderContent() {
        return <div>
            {this.renderHeader()}
            {this.renderUrl()}
        </div>
    }
    renderHeader() {
        return <p>Bestilling af niveaumåling af tank</p>;
    }
    renderButton() {
        return <button class="Button" onClick={this.openForm}>Åben Formular</button>;
    }
    renderUrl() {
        return <a href="https://form.jotform.com/220401431242032">Tryk her hvis den ikke åbner</a>
    }
    
    render() {
        return this.renderContent();
    }
}

export default Out;
