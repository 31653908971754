/* eslint-disable */
// Plugin to add classes to the body of the page based on which route is mounted.

const IS_HOME_PAGE = 'isHomePage';
const IS_CMS_PAGE = 'isCmsPage';
const IS_PRODUCT_PAGE = 'isProductPage';
const IS_CATEGORY_PAGE = 'isCategoryPage';
const IS_SEARCH_PAGE = 'isSearchPage';
const IS_CHECKOUT = 'isCheckout';

const addClass = (className) => (args, callback, instance) => {
    document.body.classList.add(className);
    return callback.apply(instance, args);
}

const removeClass = (className) => (args, callback, instance) => {
    document.body.classList.remove(className);
    return callback.apply(instance, args);
}

export default {
    'Route/HomePage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_HOME_PAGE),
            'componentWillUnmount': removeClass(IS_HOME_PAGE)
        }
    },
    'Route/CmsPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CMS_PAGE),
            'componentWillUnmount': removeClass(IS_CMS_PAGE)
        }
    },
    'Route/ProductPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_PRODUCT_PAGE),
            'componentWillUnmount': removeClass(IS_PRODUCT_PAGE)
        }
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CATEGORY_PAGE),
            'componentWillUnmount': removeClass(IS_CATEGORY_PAGE)
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_SEARCH_PAGE),
            'componentWillUnmount': removeClass(IS_SEARCH_PAGE)
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CHECKOUT),
            'componentWillUnmount': addClass(IS_CHECKOUT)
        }
    }
}