/* eslint-disable */
import { Router as HoeksRouter, withStoreRegex, SearchPage } from 'HoeksComponent/Router/Router.component';
import {
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';
import { Route } from 'react-router-dom';
export * from 'HoeksComponent/Router/Router.component';

export class Router extends HoeksRouter {
    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE],
        {
            component: <Route exact path={ withStoreRegex('/search') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 20
        }
    ]
}

export default Router;
