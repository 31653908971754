/* eslint-disable */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { appendTokenToHeaders, debounce } from 'Util/Request';
import download from 'downloadjs';

import MyAccountCertificates from './MyAccountCertificates.component';

export const CertificateDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ContainerCertificate/ContainerCertificate.dispatcher'
)

/** @namespace Component/MyAccountCertificates/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    certificateList: state.ContainerCertificateReducer.certificateList,
    isLoading: state.ContainerCertificateReducer.isLoading,
    device: state.ConfigReducer.device
});

/** @namespace Component/MyAccountCertificates/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getCertificateList: () => CertificateDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestContainerCertificates(dispatch)
    )
});

/** @namespace Component/MyAccountCertificates/Container */
export class MyAccountCertificatesContainer extends PureComponent {
    static propTypes = {
        getCertificateList: PropTypes.func.isRequired
    };

    state = {
        query: ''
    }

    componentDidMount() {
        const { getCertificateList } = this.props;
        getCertificateList();
    }

    containerFunctions = {
        handleDownload: this.handleDownload.bind(this),
        handleInput: debounce(this.handleInput.bind(this), 1000)
    }

    getCertificateList() {
        const {
            certificateList
        } = this.props;

        const {
            query
        } = this.state;

        if (query && query !== '') {
            return certificateList.filter(({ name }) => name.includes(query));
        }

        return certificateList
    }

    constainerProps = () => {
        return {
            certificateList: this.getCertificateList()
        };
    }

    handleInput(query) {
        this.setState(state => ({
            ...state,
            query
        }))
    }

    handleDownload(name) {
        fetch(`/documents/customer/certificate?file=${name}`,
            {
                method: 'GET',
                headers: appendTokenToHeaders({}),
                responseType: "blob"
            }).then(response => response.blob().then((blob) => download(blob, name, 'application/pdf'), console.log), console.log);
    }

    render() {
        return (
            <MyAccountCertificates
                {...this.state}
                {...this.props}
                {...this.containerFunctions}
                {...this.constainerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCertificatesContainer);
