/* eslint-disable */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { orderType } from 'Type/Account';

import MyAccountQuoteConfirmPopup from './MyAccountQuoteConfirmPopup.component';
import { CONFIRM_QUOTE_POPUP_ID } from './MyAccountQuoteConfirmPopup.config';
import MyQuotesQuery from 'Query/MyQuotesQuery';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Order/Order.dispatcher'
);

/** @namespace Component/MyAccountQuoteConfirmPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    order: state.OrderReducer.order,
    payload: state.PopupReducer.popupPayload[CONFIRM_QUOTE_POPUP_ID] || {},
});

/** @namespace Component/MyAccountQuoteConfirmPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hidePopup: () => dispatch(showPopup(null, {})),
    showErrorNotification: (error) => dispatch(showNotification('error', getErrorMessage(error))),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    getOrderList: () => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestOrders(dispatch)
    )
});

/** @namespace Component/MyAccountQuoteConfirmPopup/Container */
export class MyAccountQuoteConfirmPopupContainer extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            order: orderType,
            increment_id: PropTypes.string
        }).isRequired,
        showNotification: PropTypes.func.isRequired,
        getOrder: PropTypes.func.isRequired
    };

    state = {
        order: {},
        isLoading: false
    };

    containerProps = () => {
        const { payload: { accept } } = this.props;
        const { isLoading, order, order: { state } } = this.state;
        const isOrdered = state ? true : false;
        return {
            accept, 
            order,
            isOrdered,
            isLoading
        };
    }

    componentDidUpdate(prevProps) {
        const { payload: { increment_id: prevId } } = prevProps;
        const { payload: { increment_id: id } } = this.props;
        if (id !== prevId) {
            this.setState({order: {}})
        }
    }

    containerFunctions = {
        onAccept: this.onAccept.bind(this),
        onReject: this.onCancel.bind(this)
    };

    onAccept(e) {
        const { payload: { increment_id: id }, getOrderList, showSuccessNotification } = this.props;
        this.setState({ isLoading: true });
        const mutation = MyQuotesQuery.getAcceptMutation(id);
        const { order } = this.state;
        const newOrder = { ...order, state: 'processing'};

        return fetchMutation(mutation).then(
            /** @namespace Component/MyAccountQuoteConfirmPopup/Container/getAcceptMutation */
            () => {
                showSuccessNotification(__('Order was successfully placed!'));
                this.setState({ isLoading: false, order: newOrder }, getOrderList);
            },
            this.onError
        );

    }
    
    onCancel(e) {
        const { payload: { increment_id: id }, getOrderList, showSuccessNotification } = this.props;
        this.setState({ isLoading: true });
        const mutation = MyQuotesQuery.getRejectMutation(id);
        const { order } = this.state;
        const newOrder = { ...order, state: 'processing'};

        return fetchMutation(mutation).then(
            /** @namespace Component/MyAccountQuoteConfirmPopup/Container/getAcceptMutation */
            () => {
                showSuccessNotification(__('Order was successfully rejected!'));
                this.setState({ isLoading: false, order: newOrder }, getOrderList);
            },
            this.onError
        );
    }

    onError = (error) => {
        const { showErrorNotification } = this.props;
        this.setState({ isLoading: false });
        showErrorNotification(error);
    };

    render() {
        return (
            <MyAccountQuoteConfirmPopup
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountQuoteConfirmPopupContainer);
