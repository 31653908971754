/* eslint-disable */
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import CheckoutQuery from 'Query/Checkout.query';
import {
    fetchMutation,
} from 'Util/Request';

const aroundPaymentInformation = (args, callback, instance) => {
    const [paymentInformation] = args;
    const { saveAsQuote } = paymentInformation;
    if (saveAsQuote){
        saveAndPlaceOrder(paymentInformation, instance);
    }
    else {
        return callback.apply(instance, args);
    }
}

const saveAndPlaceOrder = async (paymentInformation, instance) => {
    const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;
    const isCustomerSignedIn = isSignedIn();
    const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

    if (!isCustomerSignedIn && !guest_cart_id) {
        return;
    }

    try {
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            guest_cart_id,
            payment_method: {
                code,
                [code]: additional_data,
                purchase_order_number
            }
        }));
        const { orderComment } = instance.state;
        const orderData = await fetchMutation(CheckoutQuery.getSaveCustomerQuote(guest_cart_id, orderComment));
        
        const { placeOrder: { order: { order_id } } } = orderData;
        instance.setDetailsStep(order_id, true);
    } catch (e) {
        instance._handleError(e);
    }
} 


export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: aroundPaymentInformation
        }
    }
}