import { connect } from 'react-redux';

import {
    MenuOverlayContainer as SourceMenuOverlay
} from 'HoeksComponent/MenuOverlay/MenuOverlay.container';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Menu/Menu.container';

/** @namespace HoeksPwa/Component/MenuOverlay/Container/MenuOverlayContainer */
export class MenuOverlayContainer extends SourceMenuOverlay {
    _getMenuOptions() {
        const { header_content: { header_menu, mobile_menu } = {} } = window.contentConfiguration;
        const { device } = this.props;

        if (device.isMobile) {
            return {
                identifier: mobile_menu || 'burger-menu'
            };
        }

        return {
            identifier: header_menu || 'new-main-menu'
        };
    }
}

export default connect(sourceMapStateToProps, sourceMapDispatchToProps)(MenuOverlayContainer);
