/* eslint-disable */
const noRender = (args, callback, instance) => {
    return null;
};

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            renderCreateAccountButton: noRender
        }
    }
};
