/* eslint-disable */

import { connect } from 'react-redux';
import MyAccountMyOrders from './MyAccountMyOrders.component';
import { MyAccountMyOrdersContainer as SourceMyAccountMyOrders, mapStateToProps, mapDispatchToProps } from 'HoeksComponent/MyAccountMyOrders/MyAccountMyOrders.container';
export * from 'HoeksComponent/MyAccountMyOrders/MyAccountMyOrders.container';

/** @namespace Component/MyAccountMyOrders/Container */
export class MyAccountMyOrdersContainer extends SourceMyAccountMyOrders {
    getFilteredOrderList() {
        const { orderList } = this.props;
        return orderList.filter(({ base_order_info: { status } }) => status != 'hm_quote');
    }

    render() {
        return (
            <MyAccountMyOrders
                {...this.props}
                orderList={this.getFilteredOrderList()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountMyOrdersContainer);
