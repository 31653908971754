import { connect } from 'react-redux';

import {
    HeaderContainer as SourceHeaderContainer,
    mapStateToProps as sourceMapStateToProps
} from 'HoeksComponent/Header/Header.container';
import { mapDispatchToProps as sourceMapDispatchToProps } from 'SourceComponent/Header/Header.container';

/** @namespace HoeksPwa/Component/Header/Container/HeaderContainer */
export class HeaderContainer extends SourceHeaderContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onBurgerMenuClick: this.onBurgerMenuClick.bind(this),
        onBurgerMenuOutsideClick: this.onBurgerMenuOutsideClick.bind(this)
    };

    onBurgerMenuClick() {
        const {
            showBurgerMenu
        } = this.state;

        this.setState({ showBurgerMenu: !showBurgerMenu });
    }

    onBurgerMenuOutsideClick() {
        this.setState({ showBurgerMenu: false });
    }
}

export default connect(sourceMapStateToProps, sourceMapDispatchToProps)(HeaderContainer);
