/* eslint-disable */

import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { GET_CERTIFICATE_LIST } from './ContainerCertificate.action';

export const CONTAINERCERTIFICATES = 'CONTAINERCERTIFICATES';

export const certificateList = BrowserDatabase.getItem(CONTAINERCERTIFICATES) || [];

/** @namespace Store/ContainerCertificateReducer/Reducer/getInitialState */
export const getInitialState = () => ({
    certificateList,
    isLoading: !certificateList.length
});

/** @namespace Store/ContainerCertificateReducer/Reducer */
export const ContainerCertificateReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        certificateList,
        status
    } = action;

    switch (type) {
    case GET_CERTIFICATE_LIST:
        const items = certificateList || [];

        BrowserDatabase.setItem(items, CONTAINERCERTIFICATES, ONE_MONTH_IN_SECONDS);
        return {
            ...state,
            isLoading: status,
            certificateList: items
        };

    default:
        return state;
    }
};

export default ContainerCertificateReducer;
