/* eslint-disable */
import { Header as SourceHeader } from 'HoeksComponent/Header/Header.component';
import {
  CART_OVERLAY,
  CUSTOMER_WISHLIST,
} from 'Component/Header/Header.config';
import { isSignedIn } from 'Util/Auth';
import NavigationTabs from 'Component/NavigationTabs';
import './Header.override.style.scss';
import CurrencySwitcher from 'Component/CurrencySwitcher';
import StoreSwitcher from 'Component/StoreSwitcher';
import CmsBlock from 'Component/CmsBlock';
export * from 'HoeksComponent/Header/Header.component';
import HamagLogo from "./icons/Hamag-Logo-www-pos.svg";
import CSS from 'Util/CSS';
import Logo from 'Component/Logo';
import ClickOutside from "Component/ClickOutside";
import BurgerMenu from "Component/BurgerMenu";

/** @namespace Component/Header/Component */
export class Header extends SourceHeader {
  renderMap = {
    cancel: this.renderCancelButton.bind(this),
    back: this.renderBackButton.bind(this),
    close: this.renderCloseButton.bind(this),
    share: this.renderShareWishListButton.bind(this),
    title: this.renderTitle.bind(this),
    logo: this.renderLogo.bind(this),
    compare: this.renderComparePageButton.bind(this),
    clear: this.renderClearButton.bind(this),
    edit: this.renderEditButton.bind(this),
    ok: this.renderOkButton.bind(this),
    burgermenu: this.renderBurgerMenu.bind(this)
  };

  renderBurgerMenuButton() {
    const {
      onBurgerMenuClick,
      showBurgerMenu
    } = this.props;

    const isVisible = showBurgerMenu === undefined ? false : showBurgerMenu;

    return (
        <button
            block="Header"
            elem="BurgerMenuWrapper"
            onClick={ onBurgerMenuClick }
            tabIndex="0"
            aria-label="Menu"
        >
          <div
              block="Header"
              elem="BurgerMenuText"
          >
            { __('Menu') }
          </div>
          <div
              block="Header"
              elem="Button"
              mods={ { type: 'burgermenu', isVisible } }
          />
        </button>
    )
  }

  renderBurgerMenuOverlay() {
    const {
      isCheckout,
      showBurgerMenu
    } = this.props;

    return (
        <BurgerMenu
          isCheckout={ isCheckout }
          showBurgerMenu={ showBurgerMenu }
        />
    );
  }

  renderBurgerMenu() {
    const {
      onBurgerMenuOutsideClick,
      device
    } = this.props;

    if (device.isMobile) {
      return null;
    }

    return (
        <ClickOutside
          onClick={ onBurgerMenuOutsideClick }
          key="burgermenu"
        >
          <div
            aria-label="Burger menu"
            block="Header"
            elem="BurgerMenu"
          >
            { this.renderBurgerMenuButton() }
            { this.renderBurgerMenuOverlay() }
          </div>
        </ClickOutside>
    );
  }

  renderAccountButton(isVisible) {
    const {
      onMyAccountButtonClick
    } = this.props;

    return (
        <button
            block="Header"
            elem="MyAccountWrapper"
            tabIndex="0"
            onClick={ onMyAccountButtonClick }
            aria-label="Open my account"
            id="myAccount"
        >
          <div
              block="Header"
              elem="MyAccountTitle"
          >
            { !isSignedIn() && __('Log in to webshop') }
            { isSignedIn() && __('My account') }
          </div>
          <div
              block="Header"
              elem="Button"
              mods={ { isVisible, type: 'account' } }
          />
        </button>
    );
  }

  renderLogoImage() {
    const {
        header_logo_src,
        logo_alt,
        logo_height,
        logo_width
    } = this.props;

    // if no src defined from the backend, pass null in order to display placeholder
    // and prevent unnecessary load of corrupted resource
    // const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

    CSS.setVariable(this.logoRef, 'header-logo-height', `${logo_height}px`);
    CSS.setVariable(this.logoRef, 'header-logo-width', `${logo_width}px`);

    return (
        <Logo
          src={ HamagLogo }
          alt={ logo_alt }
          title={ logo_alt }
        />
    );
  }

  renderUSPElement(uspElement) {
    return (
        <div
          block="Header"
          elem="USPElement"
        >
          <div
            block="Header"
            elem="USPElementIcon"
          />
          <div
            block="Header"
            elem="USPElementText"
          >
            { __(uspElement) }
          </div>
        </div>
    )
  }

  renderUSPButtons() {
    return (
        <>
          { this.renderSearchField() }
          { this.renderMinicart() }
          { this.renderAccount() }
        </>
    )
  }

  renderStoreSwitcher() {
    return (
        <></>
    );
  }

  renderUSPBar() {
    const {
      device
    } = this.props;

    if (device.isMobile) {
      return null;
    }

    // Temporary hardcoded values until we have approved a dynamic solution
    const uspElements = [
        '80 år i markedet',
        '100% dansk produktion',
        'Landsdækkende service'
    ];

    return (
        <div
          block="Header"
          elem="USPBarWrapper"
        >
          <div
            block="Header"
            elem="USPBar"
          >
            <div
                block="Header"
                elem="USPElementWrapper"
            >
              { uspElements?.map(this.renderUSPElement.bind(this)) }
            </div>
            <div
                block="Header"
                elem="USPButtons"
            >
              { this.renderUSPButtons() }
              <div
                  block="Header"
                  elem="USPStoreSwitcher"
              >
                { this.renderStoreSwitcher() }
              </div>
            </div>
          </div>
        </div>
    )
  }

  renderTopMenu() {
    return (
      <>
        <NavigationTabs />
      </>
    );
  }

  renderComparePageButton() {
    return <></>;
  }

  renderBackButton() {
    return null;
  }

  render() {
    const { stateMap } = this;
    const {
      navigationState: { name, isHiddenOnMobile = false },
      isCheckout,
      device,
    } = this.props;

    if (!device.isMobile) {
      // hide edit button on desktop
      stateMap[CUSTOMER_WISHLIST].edit = false;
      stateMap[CUSTOMER_WISHLIST].share = false;
      stateMap[CART_OVERLAY].edit = false;
    }

    return (
      <section block="Header" elem="Wrapper" mods={{ name, isCheckout }}>
        <header
          block="Header"
          mods={{ name, isHiddenOnMobile, isCheckout }}
          mix={{ block: 'FixedElement', elem: 'Top' }}
          ref={this.logoRef}
        >
          { this.renderUSPBar() }
          {this.renderTopMenu()}
          <nav block="Header" elem="Nav">
            {this.renderNavigationState()}
          </nav>

          {this.renderMenu()}
        </header>
      </section>
    );
  }
}

export default Header;
