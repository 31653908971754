/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ProductLinks as SourceProductLinks } from 'SourceComponent/ProductLinks/ProductLinks.component';

import './ProductLinks.override.style';

/** @namespace Component/ProductLinks/Component */
export class ProductLinks extends SourceProductLinks {}

export default ProductLinks;
