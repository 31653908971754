import Loader from '@scandipwa/scandipwa/src/component/Loader';
import React, { useEffect } from 'react';
import { usePbFinder } from 'simi-pagebuilder-react';

import { useLocation } from 'Component/Pagebuilder/hook/useLocation';
import { endPoint, integrationToken, storeCode } from 'Component/Pagebuilder/Pagebuilder.config';
import OriginalNoMatch from 'Route/NoMatch/OriginalNoMatch/OriginalNoMatch';
// eslint-disable-next-line max-len
import { PagebuilderNoMatchWrapperComponent } from 'Route/NoMatch/PagebuilderWrapper/PagebuilderNoMatchWrapper.component';

import 'Component/Pagebuilder/baseStyle.scss';

export function NoMatch(props) {
    const {
        // eslint-disable-next-line react/prop-types
        updateBreadcrumbs,
        // eslint-disable-next-line react/prop-types
        cleanUpTransition
    } = props;

    const {
        loading: pbLoading,
        pageMaskedId,
        findPage,
        pageData,
        pathToFind
    } = usePbFinder({
        endPoint,
        integrationToken,
        storeCode,
        getPageItems: true
    });

    const currentPath = useLocation();

    useEffect(() => {
        if (!pageMaskedId || currentPath !== pathToFind) {
            findPage(currentPath);
        }
    }, [currentPath, pageMaskedId, findPage]);

    if (pbLoading) {
        return (
            // eslint-disable-next-line react/no-unknown-property
            <div block="LoaderContainer">
                <Loader isLoading />
            </div>
        );
    } if (pageMaskedId
        && pageMaskedId !== 'notfound') {
        return (
            <PagebuilderNoMatchWrapperComponent
              pageMaskedId={ pageMaskedId }
              pageData={ pageData }
              endPoint={ endPoint }
              updateBreadcrumbs={ updateBreadcrumbs }
              cleanUpTransition={ cleanUpTransition }
            />
        );
    } if (pageMaskedId === 'notfound') {
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        return <OriginalNoMatch { ...props } />;
    }

    return null;
}

export default NoMatch;
