import { getStaticReducers as baseGetStaticReducers } from 'SourceStore/index';
import BurgerMenuReducer from 'Store/BurgerMenu/BurgerMenu.reducer';

/** @namespace HoeksPwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...baseGetStaticReducers(),
    BurgerMenuReducer
});

export default function injectStaticReducers(store) {
    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
