/* eslint-disable */


import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import { DeviceType } from 'Type/Device';
import Field from 'Component/Field';

import './MyAccountCertificates.style';
import { TEXT_TYPE } from 'Component/Field/Field.config';

/** @namespace Component/MyAccountCertificates/Component */
export class MyAccountCertificates extends PureComponent {
    static propTypes = {
        certificateList: PropTypes.shape({
            name: PropTypes.string
        }),
        isLoading: PropTypes.bool.isRequired,
        device: DeviceType.isRequired
    };

    renderInput() {
        const { query, handleInput } = this.props;

        return (
            <Field
                onChange={ handleInput }
                value={ query }
                type={ TEXT_TYPE }
                label={ __('Filter') }
            />
        );
    }

    renderNoDocuments() {
        const { device } = this.props;
        return (
            <tr block="MyAccountCertificates" elem="NoDocuments">
                { /* eslint-disable-next-line no-magic-numbers */}
                <td colSpan={device.isMobile ? 3 : 4}>{__('You have no documents available here.')}</td>
            </tr>
        );
    }

    renderHeadingRow() {
        return (
            <tr>
                <th>{__('Name')}</th>
                <th>{__('Download')}</th>
            </tr>
        );
    }

    renderTable() {
        return (
            <table block="MyAccountCertificates" elem="Table">
                <thead>
                    {this.renderHeadingRow()}
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
        );
    }

    renderRow = (document) => {
        const { handleDownload } = this.props;
        const { name } = document;
        return (
            <tr key={name} block="MyAccountCertificates">
                <td>{name}</td>
                <td>
                    <div block="MyAccountCertificates" elem="DownloadFile">
                        <button
                            block="Button MyAccountCertificates"
                            onClick={() => handleDownload(name)}
                        >
                            <span>{__('Download')}</span>
                        </button>
                    </div>
                </td>
            </tr>
        );
    };

    renderRows() {
        const { certificateList, isLoading } = this.props;

        if (!isLoading && !certificateList.length) {
            return this.renderNoDocuments();
        }
        return certificateList.map(this.renderRow);
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="MyAccountCertificates">
                <Loader isLoading={isLoading} />
                { this.renderInput() }
                {this.renderTable()}
            </div>
        );
    }
}

export default MyAccountCertificates;
