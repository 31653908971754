/* eslint-disable */
import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';

const removeCreateAccount = (member, context) => {
    return [
        ...member.filter(({position}) => position!=61)
    ];
};

const noRender = () => {
    return null;
}

export default {
    'Component/MyAccountSignIn/Component': {
        'member-function': {
            renderAdditionalField: noRender
        }
    },
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: removeCreateAccount
        }
    }
};
