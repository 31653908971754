/* eslint-disable */
import { CONTAINERCERT } from 'Component/MyAccountCertificates/MyAccountCertificates.config';
import { MEDIADOCUMENT } from 'Component/MyAccountDocuments/MyAccountDocuments.config';
import MyAccountCertificates from 'Component/MyAccountCertificates';
import MyAccountDocuments from 'Component/MyAccountDocuments';
import MyAccountMyQuotes from 'Component/MyAccountMyQuotes';
import ContainerCertificateReducer from 'Store/ContainerCertificate/ContainerCertificate.reducer';
import MediaDocumentReducer from 'Store/MediaDocument/MediaDocument.reducer';
import { MY_DOWNLOADABLE } from 'Type/Account';
import Out from 'Component/Out';
import getStore from 'Util/Store';

const addToTab = (member, instance) => {
    const { 'address-book': address, dashboard, 'my-orders': orders,  ...rest} = member;
    delete rest[MY_DOWNLOADABLE];
    return ({
        ['address-book']: address,
        dashboard,
        ...customerSpecific(),        
        ['my-orders']: orders,
        ['quotes']: {
            url: '/quotes',
            name: __('My quotes')
        },
        [CONTAINERCERT]: {
            url: '/certificates',
            name: __('Container Certificates')
        },
        [MEDIADOCUMENT]: {
            url: '/media',
            name: __('Media Documents')
        },
        ...rest
    });
}

const customerSpecific = (instance) => {
    const state = getStore().getState();
    const groupid = state?.MyAccountReducer?.customer?.group_id;
    if (groupid != 7) return {};
    return {
        ['bestilling']: {
            url: '/bestilling',
            name: <a style={{'text-decoration': 'none', color: 'black'}} href='https://form.jotform.com/220401431242032'>Bestilling af niveaumåling</a>
        },
    };
}
const addToRenderMap = (member, instance) => {
    return({
        ...member,
        [CONTAINERCERT]: MyAccountCertificates,
        [MEDIADOCUMENT]: MyAccountDocuments,
        ['bestilling']: Out,
        ['quotes']: MyAccountMyQuotes
    });
}

const addReducer = (args, callback, instance) => {
    return {
        ...callback(...args),
        ContainerCertificateReducer,
        MediaDocumentReducer
    }
}

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;
    return { ...callback(...args), customer: state.MyAccountReducer.customer };
};


export default {
    'Route/MyAccount/Container': {
        'static-member': {
            'tabMap': addToTab
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            'renderMap': addToRenderMap
        }
    },
    'Store/Index/getReducers':{
        'function':  addReducer
    },
    'Route/MyAccount/Container/mapStateToProps':{
        function: mapStateToProps
    }
}