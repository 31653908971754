/* eslint-disable */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { baseOrderInfoType } from 'Type/Account';
import { formatPrice } from 'Util/Price';
import { MyAccountOrderTableRow } from 'Component/MyAccountOrderTableRow/MyAccountOrderTableRow.component';

import './MyAccountQuoteTableRow.style';

/** @namespace Component/MyAccountQuoteTableRow/Component */
export class MyAccountQuoteTableRow extends MyAccountOrderTableRow {
    renderActions() {
        const { onAccept, onReject } = this.props;
        return (
            <div block="MyAccountQuoteTableRow" elem="ActionsWrapper">
                <div block="MyAccountQuoteTableRow" elem="OrderQuote">

                    <button
                        block="Button"
                        mix={{ block: 'MyAccountQuoteTableRow', elem: 'OrderQuote' }}
                        onClick={onAccept}
                    >
                        {__('Place')}
                    </button>
                </div>
                <div block="MyAccountQuoteTableRow" elem="OrderQuote">
                    <button
                        block="Button"
                        mix={{ block: 'MyAccountQuoteTableRow', elem: 'OrderQuote' }}
                        onClick={onReject}
                    >
                        {__('Reject')}
                    </button>
                </div>
            </div>
        );
    }

    render() {
        const {
            base_order_info: {
                created_at,
                name,
                status_label,
                increment_id,
                grand_total_excl_tax
            },
            onViewClick,
            currency_code
        } = this.props;

        return (
            <tr onClick={onViewClick} block="SourceMyAccountQuoteTableRow">
                <td>{increment_id ? `#${increment_id}` : ''}</td>
                <td>{name}</td>
                <td>{created_at}</td>
                <td>{status_label}</td>
                <td block="hidden-mobile">
                    {grand_total_excl_tax ? formatPrice(grand_total_excl_tax, currency_code) : ''}
                </td>
                <td>
                    {this.renderActions()}
                </td>
            </tr>
        );
    }
}

export default MyAccountQuoteTableRow;
