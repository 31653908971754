/* eslint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { NavigationTabs as SourceNavigationTabs } from "SourceComponent/NavigationTabs/NavigationTabs.component"

import './NavigationTabs.override.style';

/** @namespace Component/NavigationTabs/Component */
export class NavigationTabs extends SourceNavigationTabs {
   
}

export default NavigationTabs;
