/* eslint-disable */


import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { appendTokenToHeaders } from 'Util/Request';
import download from 'downloadjs';

import MyAccountDocuments from './MyAccountDocuments.component';

export const MediaDocumentDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MediaDocument/MediaDocument.dispatcher'
)

/** @namespace Component/MyAccountDocuments/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    documentList: state.MediaDocumentReducer.documentList,
    isLoading: state.MediaDocumentReducer.isLoading,
    device: state.ConfigReducer.device
});

/** @namespace Component/MyAccountDocuments/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getDocumentList: () => MediaDocumentDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestMediaDocuments(dispatch)
    )
});

/** @namespace Component/MyAccountDocuments/Container */
export class MyAccountDocumentsContainer extends PureComponent {
    static propTypes = {
        getDocumentList: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { getDocumentList } = this.props;
        getDocumentList();
    }

    containerFunctions = {
        handleDownload: this.handleDownload.bind(this)
    }

    handleDownload(name) {
        fetch(`/documents/customer/document?file=${name}`,
            {
                method: 'GET',
                headers: appendTokenToHeaders({}),
                responseType: "blob"
            }).then(response => response.blob().then((blob) => download(blob, name, 'application/pdf'), console.log), console.log);
    }

    render() {
        return (
            <MyAccountDocuments
                {...this.props}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountDocumentsContainer);
