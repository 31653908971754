import {
    FETCH_BURGER_MENU,
    FETCH_BURGER_MENU_FAILURE,
    FETCH_BURGER_MENU_SUCCESS
} from 'Store/BurgerMenu/BurgerMenu.action';

/** @namespace HoeksPwa/Store/BurgerMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    burgerMenu: null
});

/** @namespace HoeksPwa/Store/BurgerMenu/Reducer/BurgerMenuReducer */
export const BurgerMenuReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;
    switch (type) {
    case FETCH_BURGER_MENU:
        return {
            ...state
        };
    case FETCH_BURGER_MENU_FAILURE:
        const { error } = action;
        return {
            ...state,
            error
        };
    case FETCH_BURGER_MENU_SUCCESS:
        const { burgerMenu } = action;
        return {
            ...state,
            error: null,
            burgerMenu
        };
    default:
        return state;
    }
};

export default BurgerMenuReducer;
