/* eslint-disable */

// imports the original component from vendor folder
import SourceProductAttributeValue from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import Html from 'Component/Html';
// export everything from the source PostsListing
export * from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

/** @namespace Component/ProductAttributeValue/ProductAttributeValue.component */
export class ProductAttributeValue extends SourceProductAttributeValue {
  renderWeightAttribute() {
    const {
      attribute: { attribute_value },
    } = this.props;

    let weightValue;

    if (attribute_value >= 0) {
      weightValue = Math.round(attribute_value * 10) / 10;
    } else {
      weightValue = Math.floor(attribute_value * 1000);
    }

    return <div block="ProductAttributeValue">{weightValue}</div>;
  }
  renderAttributeByType() {
    const {
      attribute: { attribute_type },
    } = this.props;

    if (attribute_type === 'weight') {
      return this.renderWeightAttribute();
    } else {
      return super.renderAttributeByType();
    }
  }
}

export default ProductAttributeValue;
